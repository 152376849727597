.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  background-color: #F6F6F6;
}

.witenTabview {
  width: 100%;
}


.video-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  padding: 5px;
  margin-right: 27px;
}